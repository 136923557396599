import { useRef, useState } from "react";
import RolesHeader from "./RolesHeader";
import roleData from "../../data/roles.json";
import Carousel from "./Carousel";
import {
  Flex,
  Heading,
  Text,
  Image,
  VStack,
  Box,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import arrowSrc from "../../assets/arrow-upright.svg";
import armyChallengeSrc from "../../assets/logo-challenge-army.png";
import ButtonAlt from "../../Components/ButtonAlt";
import backgroundSrc from "../../assets/bg-gradient.svg";

import { css } from "@emotion/react";
export interface RoleData {
  [name: string]: Role;
}

export type Role = {
  name: string;
  overview: string;
  //attributes: string[];
  attributes: { name: string; icon: string }[];
  size: string;
  equipment: string[];
  code: string;
};

const Roles = () => {
  const roles: RoleData = roleData as any;
  const [selectedRole, setSelectedRole] = useState<Role>(roleData["INS"]);
  const setRole = (name: string) => {
    setSelectedRole(roles[name]);
  };

  return (
    <>
      <Box
        css={css`
          touch-action: auto;
          pointer-events: auto;
          background-image: url(${backgroundSrc}) !important;
          background-size: cover;
        `}
      >
        <RolesHeader role={selectedRole} />
        <Carousel roles={roles} setRole={setRole} />
        <Flex
          width="100%"
          flexDirection={{ base: "column", lg: "row" }}
          gap="30px"
          padding="25px"
        >
          <Flex width="100%" flexDirection="column">
            <Heading variant="labelYellow"> OVERVIEW</Heading>
            <Text size="13px">{selectedRole.overview}</Text>
          </Flex>
          <Flex width="100%" flexDirection="column">
            <Heading variant="labelYellow"> UNIT SIZE</Heading>
            <Text size="13px">{selectedRole.size}</Text>
          </Flex>
          <Flex width="100%" flexDirection="column">
            <Heading variant="labelYellow"> ATTRIBUTES</Heading>

            <Grid
              templateColumns="repeat(4, 1fr)"
              gap={{ base: 3, md: 6 }}
              marginTop="25px"
              marginBottom="25px"
            >
              {selectedRole.attributes.map((attr, i) => (
                <GridItem w="100%" key={i}>
                  <Flex flexDirection="column" alignItems="center">
                    <Image
                      width="40px"
                      height="auto"
                      src={`${process.env.PUBLIC_URL}/role-attributes/${attr.icon}.svg`}
                    />
                    <Text
                      fontSize={{ base: "7px", sm: "9px" }}
                      marginTop="5px"
                      variant="label"
                      textAlign="center"
                    >
                      {attr.name.toLocaleUpperCase()}
                    </Text>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Flex>
          <Flex width="100%" flexDirection="column">
            <Heading variant="labelYellow"> EQUIPMENT</Heading>
            {selectedRole.equipment.map((equipment, index) => (
              <Text key={index} size="13px" marginTop="3px">
                <Text as="span" color="#F1B700">
                  —
                </Text>{" "}
                {equipment}
              </Text>
            ))}
          </Flex>
        </Flex>
      </Box>
      <Flex
        gap="25px"
        backgroundColor="black"
        flexDirection="column"
        display={{ base: "flex", lg: "none" }}
        height="280px"
      >
        <Heading marginTop="37px" variant="clock" size="12px" width="250px">
          LEARN MORE ABOUT THE ARMY ROLES
        </Heading>
        <Box margin="auto">
          <ButtonAlt
            onClick={() =>
              window.open(
                "https://army.defencejobs.gov.au/jobs/combat-and-security",
                "_blank"
              )
            }
          >
            EXPLORE <Image marginLeft="4px" display="inline" src={arrowSrc} />
          </ButtonAlt>
        </Box>
        <Image
          margin="auto"
          marginBottom="50px !important"
          width="129px"
          src={armyChallengeSrc}
        />
        <Text
          display="block"
          margin="auto"
          textAlign="center"
          fontSize="10px"
          opacity="0.5"
          onClick={() => (window.location.href = "/terms")}
        >
          Terms &amp; Conditions
        </Text>
      </Flex>
      <Flex
        gap="25px"
        backgroundColor="black"
        alignItems="center"
        flexDirection="row"
        display={{ base: "none", lg: "flex" }}
        position={{ base: "static", lg: "static" }}
        bottom="0"
        left="0"
        right="0"
        height="211px"
        justifyContent="space-around"
      >
        <Image width="129px" src={armyChallengeSrc} />
        <Heading margin="0" variant="clock" size="12px">
          LEARN MORE ABOUT THE ARMY ROLES
        </Heading>
        <ButtonAlt
          onClick={() =>
            window.open(
              "https://army.defencejobs.gov.au/jobs/combat-and-security?utm_source=Interactive%20Battlespace&utm_medium=AR&utm_campaign=August%202022&utm_content=Information%20Carousel",
              "_blank"
            )
          }
        >
          EXPLORE <Image marginLeft="4px" display="inline" src={arrowSrc} />
        </ButtonAlt>
      </Flex>
      <Box
        display={{ base: "none", lg: "block" }}
        background="black"
        paddingBottom="20px"
        className="touchable"
      >
        <Text
          display="block"
          margin="auto"
          textAlign="center"
          fontSize="10px"
          opacity="0.5"
          onClick={() => (window.location.href = "/terms")}
        >
          Terms &amp; Conditions
        </Text>
      </Box>
    </>
  );
};

export default Roles;

import { useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Heading, Image } from "@chakra-ui/react";

import infoIconSrc from "../assets/info-icon.svg";

import { motion } from "framer-motion";
import INS from "../assets/portraits/profile_INS_s.png";
import ADO from "../assets/portraits/profile_ADO_s.png";
import AOB from "../assets/portraits/profile_AOB_s.png";
import AOP from "../assets/portraits/profile_AOP_s.png";
import ARV from "../assets/portraits/profile_ARV_s.png";
import INR from "../assets/portraits/profile_INR_s.png";
import ASO from "../assets/portraits/profile_ASO_s.png";
import { RadioComms } from "../useStore";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const ARComms = ({
  children,
  radioComms,
}: {
  children?: any;
  radioComms?: RadioComms;
}) => {
  const images = {
    INS: INS,
    INR: INR,
    ADO: ADO,
    AOP: AOP,
    ASO: ASO,
    ARV: ARV,
    AOB: AOB,
  } as any;
  const navigate = useNavigate();
  return (
    <Flex
      width="100%"
      height="110px"
      as={motion.div}
      /*@ts-ignore*/
      transition={{
        duration: 100,
        ease: "easeIn",
      }}
      flexDirection="row"
      background="rgba(0, 0, 0, 0.2)"
      backdropFilter="blur(8px)"
      padding="15px"
      animation="transform 10.5s ease-in-out"
    >
      <Flex
        flexDirection="column"
        width="125px"
        alignContent="center"
        justifyContent="center"
      >
        <Image src={images[radioComms?.unit!]}></Image>
      </Flex>
      <Flex flexDirection="column" alignItems="start" flexGrow={1} width="100%">
        <Flex
          css={css`
            touch-action: auto;
            pointer-events: auto;
          `}
          margin="0 !important"
          width="100%"
        >
          <Heading flexGrow={1} textAlign="left" variant="yellow">
            {radioComms?.title}
          </Heading>
          <Image
            src={infoIconSrc}
            onClick={() => {
              navigate("/roles");
            }}
          />
        </Flex>
        <Flex flexGrow={1} width="100%">
          <Box
            fontSize="13px"
            lineHeight="18px"
            width="100%"
            height="100%"
            color="white"
          >
            {radioComms?.content}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ARComms;
